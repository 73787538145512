import React from "react"
import Layout from "../components/layout"
import { useStaticQuery, graphql } from "gatsby"
import SEO from "../components/seo"
import { PageHeader, Services } from "../components/sections"
import {
  eline,
  fibre,
  helpInternet,
  internetdata,
  ipline,
  ipvpn,
  nbn,
} from "../images/internetanddata"
import {
  BlackSection,
  DetailSection,
  IntroSection,
  OfferSection,
  Points,
} from "../components/structure"
import { AboutCard } from "../components/elements"

const weOffer = [
  {
    id: 1,
    src: nbn,
    title: "NBN",
    content:
      "The NBN is now available in residential and business-grade options. We’ll take care of connection, installation, and maintenance of a network that will replace your need for analogue phone lines, ADSL broadband and other dated technologies, and give your business a stronger, speedier connection.",
    order: 0,
  },
  {
    id: 2,
    src: fibre,
    title: "Fibre",
    content:
      "Fibre-optic internet is a broadband connection with meteoric speeds and a low lag time. This means increased efficiency for your business and increased satisfaction among your team members and customers.",
    order: 1,
  },
  {
    id: 3,
    src: eline,
    title: "E-Line",
    content:
      "E-line is an ideal option if you’re looking for an extremely reliable network service for your organisation. It provides a variety of bandwidths and can increase business efficiency by still operating at a reduced speed if there is a fault on one of the lines.",
    order: 0,
  },
  {
    id: 4,
    src: ipline,
    title: "IP-Line",
    content:
      "Our experienced team offers IP-line connection and installation, providing a high-speed network service for your business. This is a popular internet connection choice for businesses due to its increased accessibility to Cloud-based services and lower costs.",
    order: 1,
  },
  {
    id: 5,
    src: ipvpn,
    title: "IP VPN",
    content:
      "By using IP VPN, we can set up a network of secure and reliable private connections for your business. There are various benefits to this setup, ranging from secure remote access to easy, stress-free login for you and your team.",
    order: 0,
  },
]

const points = [
  {
    id: 1,
    point: "Your personnel are satisfied and productive",
  },
  {
    id: 2,
    point: "There’s no time wasted waiting for slow connections",
  },
  {
    id: 3,
    point: "You have access to a full-range of online resources",
  },
  {
    id: 4,
    point: "You can work remotely without hassle.",
  },
]
export default function InternetData() {
  const data = useStaticQuery(graphql`
    query {
      index: file(relativePath: { eq: "headers/mobilityProduct.png" }) {
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `)

  const index = [
    data.index.childImageSharp.fluid,
    `linear-gradient(0deg,
      rgba(0, 0, 0, 0.30),
      rgba(0, 0, 0, 0.30))`,
  ].reverse()
  return (
    <Layout>
      <SEO title="Internet & Data" />
      <PageHeader
        fluid={index}
        title="Your business deserves flawless internet connection."
        subtitle="Connect your business to the internet today"
        catchy="Internet & Data"
      />
      <IntroSection
        title="Internet & Data Services"
        content="In today’s world, internet connection is a big deal. Without a reliable internet connection, your business will suffer from decreased productivity, increased costs, unsatisfied employees and customers, and missed opportunities. Avoid all of that with our effective and reliable internet services that you can count on. Choose from our wide range of customised options and let the internet work for your business."
        src={internetdata}
      />
      <BlackSection title="Call today for reliable internet connection" />
      <IntroSection
        title="How we will help you?"
        content="We understand how vital internet connection is to a modern business, which is why we offer a selection of efficient internet services to help your organisation run as smoothly as possible. Utilising our years of experience and expertise, our team will provide you with high-speed and reliable internet connection, monitoring, and troubleshooting. For a competitive price, let us equip your business with the tools you need to connect your business to the internet."
        src={helpInternet}
      />
      <OfferSection title="What we offer:">
        {weOffer.map((item, id) => (
          <AboutCard
            key={item.id}
            order={item.order}
            src={item.src}
            to={item.to}
            title={item.title}
            content={item.content}
          />
        ))}
      </OfferSection>
      <DetailSection
        paragraph1="A strong internet connection is non-negotiable for a successful business. When you have fast and efficient access to the internet through our internet services, your business benefits because:"
        paragraph2="Our range of internet services provide your business with affordable internet access and a variety of other benefits, including ongoing monitoring, troubleshooting and network consultation, backup connections, and router management."
      >
        {points.map((item, id) => (
          <Points key={item.id}>{item.point}</Points>
        ))}
      </DetailSection>
      <Services />
      <BlackSection title="Get in touch today to find out how we can use our internet solutions to help your business." />
    </Layout>
  )
}
